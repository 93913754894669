import {
    ButtonVariant,
    DriscollsButton,
    DriscollsCheckbox,
    DriscollsHContainer,
    DriscollsLoadingButton,
    DriscollsSelect,
    DriscollsTextField,
    DriscollsToastSeverity,
    DriscollsTranslatedValue,
    Language,
    useLang,
    yup,
    yupResolver
} from '@driscollsinc/style-guide-web2.0';
import { withOktaAuth } from '@okta/okta-react';
import ApplicationContext from 'context/ApplicationContext';
import usePB from 'hooks/v2/usePB';
import { NAMESPACE } from 'i18n';
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { routes, routesName } from 'routes/routes';
import style from './PB.module.scss';

const schema = yup.object({
    Name: yup.string().required(),
    DataType: yup.string().required(),
    Language: yup.string().required(),
    Selected: yup.boolean()
});

const LanguageMap = {
    [Language.ENGLISH]: 'English',
    [Language.SPANISH]: 'Spanish'
};

const PBUpdate = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const { companyNumber, id, companyId } = useParams();
    const { updateField, loading } = usePB();
    const { getTextTranslated } = useLang([NAMESPACE.PB]);
    const { setShowToast } = useContext(ApplicationContext);
    const [disabled, setDisabled] = useState(false);
    const [searchParams] = useSearchParams();
    const [attributeCount, setAttributeCount] = useState(0);
    const [data, setData] = useState<PBField>({
        Id: id,
        Name: '',
        Datatype: '',
        Language: '',
        CompanyId: companyId,
        ...state?.fields
    });
    const {
        reset,
        handleSubmit,
        formState: { isValid, errors }
    } = useForm({
        resolver: yupResolver(schema)
    });

    useEffect(() => {
        reset(data);
        handleSubmit(() => undefined)();
    }, [data]);

    useEffect(() => {
        if (id !== undefined) setDisabled(true);
        const fieldsCount = searchParams.get('fieldsCount');
        const count = fieldsCount ? parseInt(fieldsCount) : 0;
        setAttributeCount(count);
    }, [id, searchParams]);
    // State check until we move this logic to BE API
    useEffect(() => {
        if (id && !state) {
            navigate(`${routes[routesName.PB].getRoute(companyNumber, companyId)}`);
        }
    }, [id, state]);

    const onSave = async () => {
        const response = await updateField(data, attributeCount);
        if (response) {
            setShowToast({
                toastMessage: getTextTranslated(id !== undefined ? 'Field Updated Successfully!' : 'New Field Added', [NAMESPACE.PB])
            });
            navigate(-1);
        } else {
            setShowToast({ severity: DriscollsToastSeverity.ERROR, toastMessage: getTextTranslated('Some Error Occurred', [NAMESPACE.PB]) });
            navigate(-1);
        }
    };

    const handleFieldChange = (field: keyof PBField, value: string | boolean) => {
        setData((prev) => ({ ...prev, [field]: value }));
    };
    return (
        <>
            <DriscollsHContainer className={style.pbUpdate} noPadding>
                <DriscollsTextField
                    value={id !== undefined ? data.Attribute : data.Name}
                    onChange={(e) => handleFieldChange('Name', e.target.value)}
                    className={`${style.fitParent} ${style.inputText}`}
                    label="Name"
                    error={errors.Name && errors?.Name.message}
                    name="Name"
                    nameSpacing={NAMESPACE.PB}
                    disabled={disabled}
                    maxLength={20}
                />

                <DriscollsSelect
                    defaultValue={data.DataType ? { label: data.DataType, value: data.DataType } : null}
                    value={data.DataType ? { label: data.DataType, value: data.DataType } : null}
                    onChange={(e) => handleFieldChange('DataType', (e ?? { value: '' }).value)}
                    className={style.customWidth}
                    containerClassName={style.fitParent}
                    options={[
                        { label: getTextTranslated('Number', [NAMESPACE.PB]), value: 'Number' },
                        { label: getTextTranslated('Date', [NAMESPACE.PB]), value: 'Date' },
                        { label: getTextTranslated('String', [NAMESPACE.PB]), value: 'String' }
                    ]}
                    error={errors.DataType && errors?.DataType.message}
                    placeholder={<DriscollsTranslatedValue value="Data Type" nameSpacing={NAMESPACE.PB} />}
                    isDisabled={disabled}
                />

                <DriscollsSelect
                    defaultValue={data.Language ? { label: LanguageMap[data.Language], value: data.Language } : null}
                    value={data.Language ? { label: LanguageMap[data.Language], value: data.Language } : null}
                    onChange={(e) => handleFieldChange('Language', (e ?? { value: '' }).value)}
                    className={style.customWidth}
                    containerClassName={style.fitParent}
                    options={[
                        { label: LanguageMap[Language.ENGLISH], value: Language.ENGLISH },
                        { label: LanguageMap[Language.SPANISH], value: Language.SPANISH }
                    ]}
                    error={errors.Language && errors?.Language.message}
                    placeholder={<DriscollsTranslatedValue value="Language" nameSpacing={NAMESPACE.PB} />}
                    isDisabled={disabled}
                />

                <DriscollsCheckbox
                    name="Selected"
                    initiallyChecked={data.Selected}
                    onChange={(e) => {
                        handleFieldChange('Selected', e.target.checked);
                    }}
                    className={style.checkbox}
                    label={<DriscollsTranslatedValue value="Selected" nameSpacing={NAMESPACE.PB} />}
                />
            </DriscollsHContainer>
            <DriscollsHContainer className={style.pbUpdateActions}>
                <DriscollsButton
                    variant={ButtonVariant.TEXT}
                    label={<DriscollsTranslatedValue nameSpacing={NAMESPACE.PB} value="Cancel" />}
                    onClick={() => {
                        navigate(routes[routesName.PB].getRoute(companyNumber, companyId));
                    }}
                ></DriscollsButton>
                <DriscollsLoadingButton
                    label={<DriscollsTranslatedValue value="Save" nameSpacing={NAMESPACE.PB} />}
                    loading={loading}
                    disabled={!isValid}
                    onClick={onSave}
                />
            </DriscollsHContainer>
        </>
    );
};

export default withOktaAuth(PBUpdate);
