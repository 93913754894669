export const PERSONAID = {
    SUPERADMIN: 1,
    REGIONALADMIN: 2,
    HARVESTPLANNER: 3,
    GROWERADMIN: 4,
    RANCHPLANNER: 5,
    QAUSER: 5,
    QAADMIN: 6,
    PALLETBUILDER: 7,
    PTIAPP: 8,
    INVALIDPERSONA: 9,
    GROWERUSER: 10,
    PORTALUSER: 11,
    SETTLEMENTUSER: 12,
    QUALITYVIEWER: 13,
    GEOWEATHER: 14,
    REPORTBUILDER: 15,
    PTIUSER: 16,
    CLAMSCANNER: 17,
    RANCHADMIN: 18
};

export const PERSONA_NAME = {
    SUPERADMIN: 'Super Admin',
    REGIONALADMIN: 'Regional Admin',
    HARVESTPLANNER: 'Harvest Planner',
    GROWERADMIN: 'Grower Admin',
    RANCHADMIN: 'Ranch Admin',
    RANCHPLANNER: 'Ranch Planner',
    QAUSER: 'QA User',
    QAADMIN: 'QA Admin',
    PALLETBUILDER: 'Pallet Builder',
    PTIAPP: 'PTI App',
    INVALIDPERSONA: 'Invalid Persona',
    GROWERUSER: 'Grower User',
    PORTALUSER: 'Portal User',
    SETTLEMENTUSER: 'Settlement User',
    QUALITYVIEWER: 'Quality Viewer',
    GEOWEATHER: 'GeoWeather',
    REPORTBUILDER: 'Report Builder',
    PTIUSER: 'PTI User',
    CLAMSCANNER: 'Clam Scanner'
};
