import { ROUTES } from 'constants/routes';
import { getTxtTranslated } from 'hooks/useLang';
import { NAMESPACE } from 'i18n';
import { IDataOptions, IInvitablePersona, IPersonaType } from './DataSetupTypes';
import { PERSONAID, PERSONA_NAME } from './persona';

export const AWS_GROUP_PREFIX = window.config.awsGroupSuffix;
const { ROOT, USER_MANAGEMENT, DASHBOARD, WEATHER, PTI } = ROUTES;
const {
    SUPERADMIN,
    REGIONALADMIN,
    HARVESTPLANNER,
    GROWERADMIN,
    RANCHPLANNER,
    QAADMIN,
    PALLETBUILDER,
    PTIAPP,
    INVALIDPERSONA,
    GROWERUSER,
    PORTALUSER,
    SETTLEMENTUSER,
    QUALITYVIEWER,
    GEOWEATHER,
    REPORTBUILDER,
    PTIUSER,
    CLAMSCANNER,
    RANCHADMIN
} = PERSONAID;

export const persona: Array<IPersonaType> = [
    {
        Id: SUPERADMIN,
        Name: PERSONA_NAME.SUPERADMIN,
        AssignmentGroup: 'SuperAdmin',
        type: 'ADMIN',
        idp: 'okta',
        allowed: true,
        landingPage: `${ROOT}${DASHBOARD}`,
        notifications: ['Ranch', 'Company', 'Region', 'Announcement', 'Support', 'Report', 'Library', 'District']
    },
    {
        Id: REGIONALADMIN,
        Name: PERSONA_NAME.REGIONALADMIN,
        AssignmentGroup: 'District',
        type: 'ADMIN',
        idp: 'okta',
        allowed: true,
        landingPage: `${ROOT}${DASHBOARD}`,
        notifications: ['Ranch', 'Company', 'Region', 'Announcement', 'Support', 'Report', 'Library', 'District']
    },
    {
        Id: HARVESTPLANNER,
        Name: PERSONA_NAME.HARVESTPLANNER,
        type: 'USER',
        AssignmentGroup: 'District',
        idp: 'okta',
        landingPage: `${ROOT}${USER_MANAGEMENT}`,
        notifications: ['Region', 'Announcement', 'Support', 'Report', 'Library']
    },
    {
        Id: GROWERADMIN,
        Name: PERSONA_NAME.GROWERADMIN,
        AssignmentGroup: 'Company',
        type: 'ADMIN',
        idp: 'cognito',
        title: `${AWS_GROUP_PREFIX}_GEP-DOTA-GrowerAdmin`,
        allowed: true,
        landingPage: `${ROOT}${DASHBOARD}`,
        notifications: ['Region', 'Announcement', 'Support', 'Report', 'Library']
    },
    {
        Id: RANCHPLANNER,
        Name: PERSONA_NAME.QAUSER,
        type: 'USER',
        AssignmentGroup: 'Ranch',
        idp: 'cognito',
        title: `${AWS_GROUP_PREFIX}_GEP-DOTA-RanchPlanner`,
        landingPage: `${ROOT}${USER_MANAGEMENT}`,
        notifications: ['Region', 'Announcement', 'Support', 'Report', 'Library']
    },
    {
        Id: QAADMIN,
        Name: PERSONA_NAME.QAADMIN,
        AssignmentGroup: 'QaAdmin',
        type: 'ADMIN',
        idp: 'okta',
        landingPage: `${ROOT}${USER_MANAGEMENT}`,
        notifications: ['Region', 'Announcement', 'Support', 'Report', 'Library']
    },
    {
        Id: PALLETBUILDER,
        Name: PERSONA_NAME.PALLETBUILDER,
        AssignmentGroup: 'Ranch',
        type: 'USER',
        idp: 'cognito',
        title: `${AWS_GROUP_PREFIX}_GEP-DOTA-PalletBuilder`,
        landingPage: `${ROOT}${USER_MANAGEMENT}`,
        notifications: ['Region', 'Announcement', 'Support', 'Report', 'Library']
    },
    {
        Id: PTIAPP,
        Name: PERSONA_NAME.PTIAPP,
        AssignmentGroup: 'Ranch',
        type: 'USER',
        idp: 'cognito',
        title: `${AWS_GROUP_PREFIX}_GEP-DOTA-PTIUser`, // TODO: rename group first
        allowed: true,
        landingPage: `${ROOT}${PTI}`,
        notifications: []
    },
    {
        Id: INVALIDPERSONA,
        Name: PERSONA_NAME.INVALIDPERSONA
    },
    {
        Id: GROWERUSER,
        Name: PERSONA_NAME.GROWERUSER,
        AssignmentGroup: 'Ranch',
        type: 'USER',
        idp: 'cognito',
        title: `${AWS_GROUP_PREFIX}_GEP-DOTA-GrowerUser`,
        allowed: true,
        landingPage: `${ROOT}${DASHBOARD}`,
        notifications: ['Region', 'Announcement', 'Support', 'Report', 'Library']
    },
    {
        Id: PORTALUSER,
        Name: PERSONA_NAME.PORTALUSER,
        type: 'USER',
        AssignmentGroup: 'PortalUser',
        idp: 'okta',
        allowed: true,
        readOnly: true,
        landingPage: `${ROOT}${DASHBOARD}`,
        notifications: ['Region', 'Announcement', 'Support', 'Report', 'Library']
    },
    {
        Id: SETTLEMENTUSER,
        Name: PERSONA_NAME.SETTLEMENTUSER,
        type: 'USER',
        AssignmentGroup: 'settlement',
        idp: 'okta',
        landingPage: `${ROOT}${USER_MANAGEMENT}`,
        notifications: ['Region', 'Announcement', 'Support', 'Report', 'Library']
    },
    {
        Id: QUALITYVIEWER,
        Name: PERSONA_NAME.QUALITYVIEWER,
        AssignmentGroup: 'Ranch',
        type: 'USER',
        idp: 'cognito',
        title: `${AWS_GROUP_PREFIX}_GEP-DOTA-QualityViewer`,
        allowed: true,
        landingPage: `${ROOT}${DASHBOARD}`,
        notifications: ['Region', 'Announcement', 'Support', 'Report', 'Library']
    },
    {
        Id: GEOWEATHER,
        Name: PERSONA_NAME.GEOWEATHER,
        AssignmentGroup: undefined,
        type: 'ADMIN',
        idp: 'okta',
        allowed: true,
        landingPage: `${ROOT}${WEATHER}`
    },
    {
        Id: REPORTBUILDER,
        Name: PERSONA_NAME.REPORTBUILDER,
        AssignmentGroup: 'Company',
        type: 'USER',
        idp: 'cognito',
        title: `${AWS_GROUP_PREFIX}_GEP-DOTA-ReportBuilder`,
        allowed: true,
        landingPage: `${ROOT}${DASHBOARD}`,
        notifications: []
    },
    {
        Id: PTIUSER,
        Name: PERSONA_NAME.PTIUSER,
        AssignmentGroup: 'District',
        type: 'USER',
        idp: 'okta',
        allowed: true,
        landingPage: `${ROOT}${PTI}`,
        notifications: []
    },
    {
        Id: CLAMSCANNER,
        Name: PERSONA_NAME.CLAMSCANNER,
        AssignmentGroup: 'Ranch',
        type: 'USER',
        idp: 'cognito',
        title: `${AWS_GROUP_PREFIX}_GEP-DOTA-ClamScanner`,
        allowed: false,
        landingPage: `${ROOT}${DASHBOARD}`,
        notifications: []
    },
    {
        Id: RANCHADMIN,
        Name: PERSONA_NAME.RANCHADMIN,
        AssignmentGroup: 'Company',
        type: 'ADMIN',
        idp: 'cognito',
        title: `${AWS_GROUP_PREFIX}_GEP-DOTA-RanchAdmin`,
        allowed: true,
        landingPage: `${ROOT}${DASHBOARD}`,
        notifications: ['Region', 'Announcement', 'Support', 'Report', 'Library']
    }
];

export const titles: Array<IDataOptions> = [
    { id: 0, label: 'Controller/Accountant', value: 'Controller/Accountant' },
    { id: 1, label: 'Grower Owner', value: 'Grower Owner' },
    { id: 2, label: 'President', value: 'President' },
    { id: 3, label: 'Production Assistant', value: 'Production Assistant' },
    { id: 4, label: 'Ranch Assistant', value: 'Ranch Assistant' },
    { id: 5, label: 'Ranch Foreman', value: 'Ranch Foreman' },
    { id: 6, label: 'Ranch Manager', value: 'Ranch Manager' },
    { id: 7, label: 'Truck Driver', value: 'Truck Driver' },
    { id: 8, label: 'Other', value: 'Other' }
];

export const palletTitles: Array<IDataOptions> = [
    { id: 0, label: 'Crew Lead', value: 'PBA-Crew-Lead' },
    { id: 1, label: 'Driver', value: 'PBA-Driver' }
];

export const ptiTitles: Array<IDataOptions> = [{ id: 0, label: 'PTI User', value: 'GROWER-PTI-User' }];

export const language: Array<IDataOptions> = [
    { id: 0, label: 'English', value: 'en' },
    { id: 1, label: 'Spanish', value: 'es' },
    { id: 2, label: 'French', value: 'fr' }
];

export const invitablePersonas: Array<IInvitablePersona> = [
    {
        id: SUPERADMIN,
        permitted: [
            { id: SUPERADMIN },
            { id: REGIONALADMIN },
            { id: GROWERADMIN },
            { id: PTIAPP },
            { id: PTIUSER },
            { id: GROWERUSER },
            { id: PORTALUSER },
            { id: QUALITYVIEWER },
            { id: GEOWEATHER },
            { id: REPORTBUILDER },
            { id: CLAMSCANNER }
        ]
    },
    {
        id: REGIONALADMIN,
        permitted: [{ id: GROWERADMIN }, { id: PTIAPP }, { id: GROWERUSER }, { id: QUALITYVIEWER }, { id: CLAMSCANNER }]
    },
    {
        id: HARVESTPLANNER,
        permitted: []
    },
    {
        id: GROWERADMIN,
        permitted: [{ id: PTIAPP }, { id: GROWERUSER }, { id: QUALITYVIEWER }, { id: CLAMSCANNER }]
    },
    {
        id: RANCHPLANNER,
        permitted: []
    },
    {
        id: QAADMIN,
        permitted: []
    },
    {
        id: PALLETBUILDER,
        permitted: []
    },
    {
        id: PTIAPP,
        permitted: []
    },
    {
        id: GROWERUSER,
        permitted: []
    },
    {
        id: PORTALUSER,
        permitted: []
    },
    {
        id: SETTLEMENTUSER,
        permitted: []
    },
    {
        id: QUALITYVIEWER,
        permitted: []
    }
];

export const getInvitablePersonas = (loggedInPersona) => {
    const loggedIn = invitablePersonas.find((item) => item.id === loggedInPersona);
    return loggedIn.permitted.map((item) => {
        const selectedPersona = persona.find((p) => p.Id === item.id);
        return {
            id: selectedPersona.Id,
            label: selectedPersona.Name,
            group: selectedPersona.AssignmentGroup,
            idp: selectedPersona.idp,
            title: selectedPersona.title
        };
    });
};

export const isPersonaIsOKTA = (personaId) => {
    const selectedPersona = persona.find((p) => p.Id === parseInt(personaId));
    return selectedPersona && selectedPersona.idp === 'okta';
};

export const isPersonaAllowedInTheApp = (personaId) => {
    const selectedPersona = persona.find((p) => p.Id === parseInt(personaId));
    return selectedPersona && selectedPersona.allowed;
};

export const isPersonaHasReadOnlyAccess = (personaId) => {
    const selectedPersona = persona.find((p) => p.Id === parseInt(personaId));
    return selectedPersona.readOnly === true;
};

export const getCountries = (countries) => {
    return (countries || []).map((item) => {
        return { label: item.Name, ...item };
    });
};

export const getPersonaLandingPage = (defaultPersonaId) => {
    return persona.find((p) => p.Id === defaultPersonaId)?.landingPage;
};

export const getPersonaByTitle = (title) => {
    return persona.find((p) => p.title?.indexOf(title) > -1);
};

export const hideUrlKey = ({ path, startIndex, endIndex }) => {
    const translatedLabels = [];
    if (endIndex) {
        const labelsToTranslate = path.slice(startIndex, endIndex);
        labelsToTranslate.forEach((label) => {
            translatedLabels.push(getTxtTranslated(label, NAMESPACE.COMMON));
        });
        return decodeURI(translatedLabels.join(' / '));
    } else {
        return getTxtTranslated(path[startIndex], NAMESPACE.COMMON);
    }
};
