/* eslint-disable indent */
import { DriscollsSpinner } from '@driscollsinc/style-guide-web2.0';
import { Grid, TextField } from '@mui/material';
import { withOktaAuth } from '@okta/okta-react';
import FormCheckbox from 'components/shared/formcontrols/FormCheckbox';
import { Persona } from 'components/shared/protected/PersonaBaseProtect';
import { ComponentName } from 'constants/ComponentEnum';
import useCheckUserEmail from 'hooks/useCheckUserEmail';
import { NAMESPACE } from 'i18n';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { useParams } from 'react-router-dom';
import DriscollsLegend from 'ui/DriscollsLegend/DriscollsLegend';
import { isUSPhoneNumber } from 'utils/validations';
import ApplicationContext from '../../context/ApplicationContext';
import { isPersonaIsOKTA, language } from '../../data/config/datasetup';
import useLang from '../../hooks/useLang';
import { TypographyTranslated } from '../shared/Typography/TypographyTranslated';
import FormSelectField from '../shared/formcontrols/FormSelectField';
import FormTextField from '../shared/formcontrols/FormTextField';
import style from './PersonalInformation.module.scss';

interface IPayloadType {
    FirstName?: string;
    firstName?: string;
    LastName?: string;
    lastName?: string;
    Email?: string;
    email?: string;
    Phone?: string;
    phone?: string;
    Lang?: string;
    gpaLang?: string;
    IsContact?: boolean;
    isWhatsAppUser?: boolean;
}

let payload: IPayloadType = {};
function PersonalInformation(props) {
    const [personalInformation, setPersonalInformation] = useState<IPayloadType>({});
    const [loginField, setLoginField] = useState();
    const [isWhatsAppUser, setIsWhatsAppUser] = useState(true);
    const { pathname } = useLocation();
    const path = pathname.split('/');
    const { userPersona } = useContext(ApplicationContext);
    const isReadOnly = path[2] === 'view';
    const { getTextTranslated } = useLang();
    const { selectedpersonaid } = useParams();
    const isOktaUser = isPersonaIsOKTA(selectedpersonaid || userPersona);
    const { checkUserEmail, error: checkUserEmailError } = useCheckUserEmail(props.oktaAuth);

    useEffect(() => {
        setPersonalInformation(props.currentEditUser[0]);
        if (props.currentEditUser[0]) {
            const { FirstName, LastName, Lang, Email, Phone, IsContact } = props.currentEditUser[0];
            payload = { firstName: FirstName, lastName: LastName, gpaLang: Lang, email: Email, phone: Phone, IsContact, isWhatsAppUser: true };
            !isOktaUser && props.parentComponent === ComponentName.EditUser && getDefaultLogin();
        }
    }, [props.currentEditUser]);

    const getDefaultLogin = async () => {
        const emailData = await checkUserEmail(payload.email, payload.phone, Number(selectedpersonaid), null);
        const cognitoProfile = getCognitoUserProfile(emailData?.UserDetails, props.currentEditUser[0]?.UserOktaId);
        setLoginField(cognitoProfile?.loginIdField ?? '');
        payload.isWhatsAppUser = cognitoProfile?.isWhatsAppUser === 'true';
        addPayloadValue('isWhatsAppUser', payload.isWhatsAppUser);
        setIsWhatsAppUser(cognitoProfile.isWhatsAppUser === 'true');
    };

    const getCognitoUserProfile = (users, userId) => {
        const cognitoUser = users.find((user) => {
            return user[0]?.UserOktaId?.toLowerCase() === userId?.toLowerCase();
        });
        return cognitoUser ? cognitoUser[0] : users[0][0];
    };

    const addPayloadValue = (fieldName: string, value: string | boolean) => {
        payload[fieldName] = fieldName === 'phone' ? '+' + value : value;
        if (fieldName === 'defaultContact') {
            payload[fieldName] = value ? '1' : '0';
        }
        if (!isOktaUser) {
            props.getCognitoPayload && fieldName !== 'isWhatsAppUser' && checkStatus(fieldName);
        }
        props.getCognitoPayload && sendPayload();
        props.getLanguage && sendUserLang();
    };

    const sendPayload = () => {
        props.getCognitoPayload(payload);
    };

    const sendUserLang = () => {
        props.getLanguage(payload.gpaLang);
    };

    const checkStatus = (field) => {
        const { getInvalidFields } = props;
        const localInvalidFields = [];
        const regex = /^[a-zA-Z\s]*$/;
        const fieldValue = payload[field];

        const isValidField = typeof fieldValue === 'string' && fieldValue.trim().length > 0 && regex.test(fieldValue);

        if (!isValidField) {
            localInvalidFields.push('Invalid or cannot be empty!');
        }

        getInvalidFields(Array.from(new Set(localInvalidFields)));
    };

    return (
        <div className={style.container}>
            <DriscollsLegend title={<TypographyTranslated nameSpacing={NAMESPACE.COMMON}>Personal Information</TypographyTranslated>} show>
                {props.loading ? (
                    <div className={style.loader}>
                        <DriscollsSpinner text="Fetching User Info" nameSpacing={NAMESPACE.USERS} />
                    </div>
                ) : (
                    <Grid item xl={11} lg={11} md={11}>
                        {Object.keys(personalInformation || {}).length > 0 &&
                            (isOktaUser ? (
                                <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 1, sm: 8, md: 12 }}>
                                    <Grid item xl={4} lg={4} md={4}>
                                        <TextField
                                            className={style.editTextField}
                                            disabled
                                            label={getTextTranslated('First Name', NAMESPACE.EVENTS)}
                                            defaultValue={personalInformation.FirstName}
                                            variant="standard"
                                            InputProps={{
                                                disableUnderline: true
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xl={4} lg={4} md={4}>
                                        <TextField
                                            className={style.editTextField}
                                            disabled
                                            label={getTextTranslated('Last Name', NAMESPACE.EVENTS)}
                                            defaultValue={personalInformation.LastName}
                                            variant="standard"
                                            InputProps={{
                                                disableUnderline: true
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xl={4} lg={4} md={4}>
                                        <TextField
                                            className={style.editTextField}
                                            disabled
                                            label={getTextTranslated('Email', NAMESPACE.EVENTS)}
                                            defaultValue={personalInformation.Email}
                                            variant="standard"
                                            InputProps={{
                                                disableUnderline: true
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xl={4} lg={4} md={4}>
                                        <TextField
                                            className={style.editTextField}
                                            disabled
                                            label={getTextTranslated('Phone', NAMESPACE.EVENTS)}
                                            defaultValue={personalInformation.Phone}
                                            variant="standard"
                                            InputProps={{
                                                disableUnderline: true
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xl={4} lg={4} md={4}>
                                        {isReadOnly ? (
                                            <TextField
                                                className={style.editTextField}
                                                disabled
                                                label={getTextTranslated('Preferred Language', NAMESPACE.EVENTS)}
                                                defaultValue={personalInformation.Lang}
                                                variant="standard"
                                                InputProps={{
                                                    disableUnderline: true
                                                }}
                                            />
                                        ) : (
                                            <FormSelectField
                                                label={getTextTranslated('Preferred Language', NAMESPACE.EVENTS)}
                                                values={language}
                                                valueField={'value'}
                                                selectedValue={personalInformation.Lang || ''}
                                                handleChange={(value) => addPayloadValue('gpaLang', value)}
                                            />
                                        )}
                                    </Grid>
                                    <Grid item xl={4} lg={4} md={4}>
                                        {!path.includes('profile') &&
                                            [Persona.SUPER_ADMIN, Persona.REGIONAL_ADMIN].includes(Number(selectedpersonaid)) && (
                                                <FormCheckbox
                                                    label={getTextTranslated('Default Contact', NAMESPACE.USERS)}
                                                    defaultChecked={personalInformation.IsContact}
                                                    handleChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                                        addPayloadValue('defaultContact', event.target.checked)
                                                    }
                                                />
                                            )}
                                    </Grid>
                                </Grid>
                            ) : (
                                <div className={style.personalInfoContainer}>
                                    <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 1, sm: 8, md: 12 }}>
                                        <Grid xs={2} sm={4} md={4}>
                                            {isReadOnly || props.parentComponent === ComponentName.UserProfile ? (
                                                <TextField
                                                    className={style.editTextField}
                                                    disabled
                                                    label={getTextTranslated('First Name', NAMESPACE.EVENTS)}
                                                    defaultValue={personalInformation.FirstName}
                                                    variant="standard"
                                                    InputProps={{
                                                        disableUnderline: true
                                                    }}
                                                />
                                            ) : (
                                                <FormTextField
                                                    label={getTextTranslated('First Name', NAMESPACE.EVENTS)}
                                                    value={personalInformation.FirstName}
                                                    handleChange={(value) => addPayloadValue('firstName', value)}
                                                />
                                            )}
                                        </Grid>
                                        <Grid xs={2} sm={4} md={4}>
                                            {isReadOnly || props.parentComponent === ComponentName.UserProfile ? (
                                                <TextField
                                                    className={style.editTextField}
                                                    disabled
                                                    label={getTextTranslated('Last Name', NAMESPACE.EVENTS)}
                                                    defaultValue={personalInformation.LastName}
                                                    variant="standard"
                                                    InputProps={{
                                                        disableUnderline: true
                                                    }}
                                                />
                                            ) : (
                                                <FormTextField
                                                    label={getTextTranslated('Last Name', NAMESPACE.EVENTS)}
                                                    value={personalInformation.LastName}
                                                    handleChange={(value) => addPayloadValue('lastName', value)}
                                                />
                                            )}
                                        </Grid>
                                        <Grid xs={2} sm={4} md={4}>
                                            {isReadOnly ? (
                                                <TextField
                                                    className={style.editTextField}
                                                    disabled
                                                    label={getTextTranslated('Preferred Language', NAMESPACE.EVENTS)}
                                                    defaultValue={personalInformation.Lang}
                                                    variant="standard"
                                                    InputProps={{
                                                        disableUnderline: true
                                                    }}
                                                />
                                            ) : (
                                                <FormSelectField
                                                    label={getTextTranslated('Preferred Language', NAMESPACE.EVENTS)}
                                                    values={language}
                                                    valueField={'value'}
                                                    selectedValue={personalInformation.Lang || ''}
                                                    handleChange={(value) => addPayloadValue('gpaLang', value)}
                                                />
                                            )}
                                        </Grid>
                                        <Grid xs={2} sm={4} md={4}>
                                            <TextField
                                                className={style.editTextField}
                                                disabled
                                                label={
                                                    getTextTranslated('Email', NAMESPACE.EVENTS) +
                                                    (loginField === 'email' ? getTextTranslated('Default', NAMESPACE.USERS) : '')
                                                }
                                                defaultValue={personalInformation.Email}
                                                variant="standard"
                                                InputProps={{
                                                    disableUnderline: true
                                                }}
                                            />
                                        </Grid>
                                        <Grid xs={2} sm={4} md={4}>
                                            <TextField
                                                className={style.editTextField}
                                                disabled
                                                label={
                                                    getTextTranslated('Phone', NAMESPACE.EVENTS) +
                                                    (loginField === 'phone' ? getTextTranslated('Default', NAMESPACE.USERS) : '')
                                                }
                                                defaultValue={personalInformation.Phone}
                                                variant="standard"
                                                InputProps={{
                                                    disableUnderline: true
                                                }}
                                            />
                                        </Grid>
                                        {props.parentComponent === ComponentName.EditUser &&
                                            payload?.phone &&
                                            loginField === 'phone' &&
                                            isUSPhoneNumber(payload.phone) && (
                                                <Grid item xs={2} sm={4} md={4}>
                                                    <FormCheckbox
                                                        className={style.checkbox}
                                                        label={getTextTranslated('Receive OTP on WhatsApp', NAMESPACE.USERS)}
                                                        value={isWhatsAppUser}
                                                        handleChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                            setIsWhatsAppUser(event.target.checked);
                                                            addPayloadValue('isWhatsAppUser', event.target.checked);
                                                        }}
                                                    />
                                                </Grid>
                                            )}
                                    </Grid>
                                </div>
                            ))}
                    </Grid>
                )}
            </DriscollsLegend>
        </div>
    );
}

export default withOktaAuth(PersonalInformation);
