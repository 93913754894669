import { syncUserManagementTranslation } from '@driscollsinc/users-management-module-ui';
import { FEATURES } from 'components/appinitializer/v2/IPersonaFeatures';
import DrcPageNotFound from 'components/shared/drc/DrcPageNotFound';
import { NotAuthorized } from 'components/shared/protected/NonAuthorized';
import PersonaBaseProtect, { Persona } from 'components/shared/protected/PersonaBaseProtect';
import { ROUTES } from 'constants/routes';
import useLang from 'hooks/useLang';
import EntityAssignment from 'pages/usermanagement/EntityAssignment';
import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { routes, routesName } from 'routes/routes';
import EditUserPersona from './EditUserPersona';
import InviteUsersPage from './InviteUsersPage';
import UpdateUserPersona from './UpdateUserPersona';
import UsersPage from './UsersPage';

const UserManagementRoutesV2 = () => {
    const { getCurrentLanguageSelected } = useLang();
    const currentLang = getCurrentLanguageSelected();

    useEffect(() => {
        syncUserManagementTranslation(currentLang);
    }, [currentLang]);

    return (
        <PersonaBaseProtect
            personasAllowed={[Persona.SUPER_ADMIN, Persona.REGIONAL_ADMIN, Persona.GROWER_ADMIN, Persona.RANCH_ADMIN]}
            featureName={FEATURES.USER_MANAGEMENT}
            access="Write"
            forbiddenComponent={<DrcPageNotFound />}
        >
            <Routes>
                <Route path="" element={<UsersPage />} />
                <Route path={ROUTES.USER_INVITE} element={<InviteUsersPage />} />
                <Route path={routes[routesName.EDIT_USER_PERSONA].path} element={<EditUserPersona />} />
                <Route path={routes[routesName.ADD_PERSONA].path} element={<UpdateUserPersona />} />
                <Route
                    path={routes[routesName.ENTITY_ASSIGNMENT].path}
                    element={
                        <PersonaBaseProtect
                            personasAllowed={[Persona.SUPER_ADMIN, Persona.REGIONAL_ADMIN, Persona.GROWER_ADMIN, Persona.RANCH_ADMIN]}
                            featureName={FEATURES.ENTITY_ASSIGNMENT}
                            access="Write"
                            forbiddenComponent={<NotAuthorized />}
                        >
                            <EntityAssignment />
                        </PersonaBaseProtect>
                    }
                />
            </Routes>
        </PersonaBaseProtect>
    );
};

export default UserManagementRoutesV2;
