export enum routesName {
    INITIALIZE_APPLICATION = 'initializeApplication',
    USERS = 'users',
    ADD_PERSONA = 'addPersona',
    EDIT_USER_PERSONA = 'editUserPersona',
    USER = 'user',
    INVITE_USER = 'invite',
    EDIT_FEATURE = 'create',
    ENTITY_ASSIGNMENT = 'entityAssignment',
    PB = 'pb',
    PB_FIELDS = 'field',
    ANNOUNCEMENT = 'announcement',
    VIEW_ANNOUNCEMENT = 'viewAnnouncement',
    CREATE_ANNOUNCEMENT = 'createAnnouncement',
    SUPPORT = 'support',
    QUERY = 'query',
    SUPPORT_RESOLUTION = 'supportResolution',
    VIEW_RESOLUTION = 'viewResolution',
    DOCUMENTS = 'documents',
    DOCUMENTS_FILE = 'documentsFile',
    LATEST = 'latest',
    DOCUMENT_SEARCH = 'documentSearch',
    PTI = 'pti',
    DASHBOARD = 'dashboard',
    PROFILE = 'profile',
    LOGOUT = 'logout',
    PB_HOME = 'pbhome'
}

export const routes = {
    [routesName.INITIALIZE_APPLICATION]: {
        path: '/InitializeApplication/',
        getRoute: () => '/InitializeApplication/'
    },
    [routesName.PROFILE]: {
        path: 'profile',
        getRoute: () => 'profile'
    },
    [routesName.LOGOUT]: {
        path: 'logout',
        getRoute: () => '/LogOut'
    },

    // user management
    [routesName.ADD_PERSONA]: {
        path: 'addPersona/user/:userId',
        getRoute: (userId: string, personaIds: string, userType: string) => `addPersona/user/${userId}?personaIds=${personaIds}&userType=${userType}`
    },
    [routesName.EDIT_USER_PERSONA]: {
        path: 'edit/user/:userId/persona/:personaId',
        getRoute: (userId: string, personaId: string) => `edit/user/${userId}/persona/${personaId}`
    },
    [routesName.INVITE_USER]: {
        path: 'invite',
        getRoute: () => 'invite'
    },
    [routesName.USER]: {
        path: 'user',
        getRoute: () => '/user'
    },
    [routesName.EDIT_FEATURE]: {
        path: 'create/:featureId',
        getRoute: (featureId: string) => `create/${featureId}`
    },
    [routesName.ENTITY_ASSIGNMENT]: {
        path: 'assignment/entity/:EntityName',
        getRoute: (entityname: string) => `assignment/entity/${entityname}`
    },

    //PB routes
    [routesName.PB]: {
        path: '/pb/:companyNumber/:companyId',
        getRoute: (companyNumber: string, companyId: string) => `/pb/${companyNumber}/${companyId}`
    },
    [routesName.PB_FIELDS]: {
        path: 'field/:id?',
        getRoute: (id?: string, count?: number) => `field/${id ?? ''}?fieldsCount=${count}`
    },
    [routesName.PB_HOME]: {
        path: ':companyNumber/:companyId',
        getRoute: '/pb'
    },

    // announcement routes
    [routesName.ANNOUNCEMENT]: {
        path: 'announcement',
        getRoute: () => '/announcement'
    },
    [routesName.VIEW_ANNOUNCEMENT]: {
        path: 'view/:announcementId',
        getRoute: (announcementId: string) => `view/${announcementId}`
    },
    [routesName.CREATE_ANNOUNCEMENT]: {
        path: 'create-announcement',
        getRoute: () => 'create-announcement'
    },

    // support routes
    [routesName.SUPPORT]: {
        path: 'support',
        getRoute: () => '/support'
    },
    [routesName.QUERY]: {
        path: 'query',
        getRoute: () => 'query'
    },
    [routesName.SUPPORT_RESOLUTION]: {
        path: 'resolution/:requestId/:responseId',
        getRoute: (requestId: string, responseId: string) => `resolution/${requestId}/${responseId}`
    },
    [routesName.VIEW_RESOLUTION]: {
        path: 'view/:requestId/:responseId',
        getRoute: (requestId: string, responseId: string) => `view/${requestId}/${responseId}`
    },

    // document library routes
    [routesName.DOCUMENTS]: {
        path: 'documents',
        getRoute: () => 'documents'
    },
    [routesName.LATEST]: {
        path: 'latest',
        getRoute: () => 'latest'
    },
    [routesName.DOCUMENTS_FILE]: {
        path: 'documents/:fileName',
        getRoute: (fileName: string) => `documents/${fileName}`
    },
    [routesName.DOCUMENT_SEARCH]: {
        path: 'search/:query',
        getRoute: (query: string) => `/library/search/${query}`
    },

    // PTI routes
    [routesName.PTI]: {
        path: ':ptiTab',
        getRoute: (ptiTab: string) => `/pti/${ptiTab}`
    },

    // Dashboard routes
    [routesName.DASHBOARD]: {
        path: ':dashboardType',
        getRoute: (dashboardType: string) => `/dashboard/${dashboardType}`
    }
};
