import {
    ApplicationPage,
    ApplicationPageTitle,
    ButtonVariant,
    DirectionEnum,
    DriscollsButton,
    DriscollsComponentSwitcher,
    DriscollsDataTable,
    DriscollsMobileList,
    DriscollsSearch,
    FaIcons,
    useLang
} from '@driscollsinc/style-guide-web2.0';
import { FEATURES } from 'components/appinitializer/v2/IPersonaFeatures';
import PersonaBaseProtect, { Persona } from 'components/shared/protected/PersonaBaseProtect';
import ApplicationContextV2 from 'context/ApplicationContextV2';
import { NAMESPACE } from 'i18n';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { routes, routesName } from 'routes/routes';
import style from './Companies.module.scss';

const ITEM_PER_PAGE = 50;

const Companies = (props) => {
    const { companyEntities } = useContext(ApplicationContextV2);
    const companyData = Object.values(companyEntities.available).map((entry) => (entry as any).entity);
    const { getTextTranslated } = useLang([NAMESPACE.COMPANIES]);
    const [value, setValue] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [page, setPage] = useState(0);
    const navigate = useNavigate();
    const columns = [
        {
            id: 'company-actions',
            size: 80,
            cell: ({ row }) => {
                return (
                    <>
                        <PersonaBaseProtect
                            personasAllowed={[Persona.SUPER_ADMIN, Persona.REGIONAL_ADMIN, Persona.GROWER_ADMIN, Persona.RANCH_ADMIN]}
                            featureName={FEATURES.PB_FIELDS_MANAGEMENT}
                            access="Write"
                        >
                            <DriscollsButton variant={ButtonVariant.TEXT}>
                                <FaIcons.FaPallet
                                    fontSize={18}
                                    onClick={() => navigate(`${routes[routesName.PB].getRoute(row.original.value, row.original.id)}`)}
                                />
                            </DriscollsButton>
                        </PersonaBaseProtect>
                    </>
                );
            }
        },
        {
            header: () => `${getTextTranslated('Company', NAMESPACE.COMPANIES)} #`,
            accessorKey: 'value'
        },
        {
            header: () => getTextTranslated('Company Name', NAMESPACE.COMPANIES),
            accessorKey: 'label'
        }
    ];

    useEffect(() => {
        if (!value) {
            setFilteredData(companyData);
        } else {
            const convertedValue = value.toLowerCase();
            setFilteredData(
                companyData.filter((v) => v.label.toLowerCase().includes(convertedValue) || v.value.toLowerCase().includes(convertedValue))
            );
        }
    }, [value]);

    return (
        <ApplicationPage>
            <ApplicationPageTitle
                title={{ text: 'Companies', nameSpace: NAMESPACE.COMPANIES }}
                description={{
                    text: 'List of all Companies',
                    nameSpace: NAMESPACE.COMPANIES
                }}
            />
            <DriscollsSearch
                label="Search"
                name="search"
                nameSpacing={NAMESPACE.COMPANIES}
                className={style.search}
                onChange={(e) => {
                    setValue(e.target.value);
                }}
                onClose={() => setValue('')}
            />
            <DriscollsComponentSwitcher
                direction={DirectionEnum.width}
                componentList={[
                    {
                        breakPoint: 799,
                        component: (
                            <DriscollsMobileList
                                data={filteredData.slice((page - 1) * ITEM_PER_PAGE, page * ITEM_PER_PAGE)}
                                selectedPage={1}
                                onPageChange={(page) => setPage(page)}
                                itemsPerPage={ITEM_PER_PAGE}
                                totalItems={companyData.length}
                                paginationText={' '}
                                hideKeys={['ranches', 'entityType', 'id']}
                                actionButtonRenderer={(row) => (
                                    <PersonaBaseProtect
                                        personasAllowed={[Persona.SUPER_ADMIN, Persona.REGIONAL_ADMIN, Persona.GROWER_ADMIN, Persona.RANCH_ADMIN]}
                                        featureName={FEATURES.PB_FIELDS_MANAGEMENT}
                                        access="Write"
                                    >
                                        <DriscollsButton variant={ButtonVariant.TEXT}>
                                            <FaIcons.FaPallet
                                                fontSize={18}
                                                onClick={() => navigate(`${routes[routesName.PB].getRoute(row.original.value, row.original.id)}`)}
                                            />
                                        </DriscollsButton>
                                    </PersonaBaseProtect>
                                )}
                            />
                        )
                    },

                    {
                        breakPoint: 800,
                        component: (
                            <DriscollsDataTable
                                data={filteredData.slice((page - 1) * ITEM_PER_PAGE, page * ITEM_PER_PAGE)}
                                className={style.companyTable}
                                columns={columns}
                                hideFilters
                                selectedPage={1}
                                onPageChange={(page) => setPage(page)}
                                itemsPerPage={ITEM_PER_PAGE}
                                totalItems={companyData.length}
                                paginationText={' '}
                            />
                        )
                    }
                ]}
            />
        </ApplicationPage>
    );
};

export default Companies;
