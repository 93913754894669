import React, { useContext, useEffect, useState } from 'react';

import { Call } from '@driscollsinc/driscolls-display-rules';
import { Box, Button, Grid } from '@mui/material';
import { withOktaAuth } from '@okta/okta-react';
import { FEATURES } from 'components/appinitializer/v2/IPersonaFeatures';
import PersonaBaseProtect, { Persona } from 'components/shared/protected/PersonaBaseProtect';
import useCheckMasterData from 'hooks/useCheckMasterData';
import { NAMESPACE } from 'i18n';
import { useNavigate } from 'react-router-dom';
import { TypographyTranslated } from '../../components/shared/Typography/TypographyTranslated';
import DuAuthenticationUtilities from '../../components/shared/Utilities/DuAuthenticationUtilities';
import Iconify from '../../components/shared/iconify/Iconify';
import GenericSearchBox from '../../components/shared/search/GenericSearchBox';
import SupportGridContainer from '../../components/support/SupportGridContainer';
import ApplicationContext from '../../context/ApplicationContext';
import { PERSONAID } from '../../data/config/persona';
import useResponsive from '../../hooks/useResponsive';
import DrcContainer from '../../layouts/dashboard/DrcContainer';
import GenericFilterSideBar from '../../layouts/dashboard/filters/GenericFilterSideBar';
import APIEndPoints from '../../services/api';
import Filter from '../../ui/DrcFilters/DrcFilter';
import TranslatedDrcButton from '../../ui/translatedButton/TranslatedDrcButton';
import { getAppliedFiltersPost } from '../../utils/helper';
import styles from './Support.module.scss';
function Support(props) {
    const { oktaAuth } = props;
    useCheckMasterData();
    const [searchInput, setSearchInput] = useState('');
    const [supportList, setSupportList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pageCount, setPageCount] = useState(0);
    const [totalUsers, setTotalUsers] = useState(0);
    const [page, setPage] = useState(1);
    const [advancedSearchOn, setAdvancedSearchOn] = useState(false);
    const [openFilter, setOpenFilter] = useState(false);
    const [executionTime, setExecutionTime] = useState(0);
    const isDesktop = useResponsive('up', 'md');
    const navigate = useNavigate();
    const { userPersona, filters } = useContext(ApplicationContext);

    const getData = async (pageNumber = 1, payload) => {
        setSupportList([]);
        setLoading(true);
        const token = await DuAuthenticationUtilities.getAccessTokenFromAuthenticationProvider(oktaAuth);
        const userOktaId = DuAuthenticationUtilities.GetOktaId(token);
        try {
            const startExecution = performance.now();
            const data = await Call(
                {
                    name: 'support',
                    url: APIEndPoints.GET_SUPPORT(),
                    method: 'POST',
                    options: {},
                    data: {
                        UserOktaId: userOktaId,
                        ...payload,
                        Offset: pageNumber === 1 ? 0 : (pageNumber - 1) * 50 + 1,
                        Limit: 50
                    }
                },
                token
            );
            const endExecution = performance.now();
            setExecutionTime(Math.round(((endExecution - startExecution) / 1000 + Number.EPSILON) * 100) / 100);
            if (data.errors.length && !data.raw) {
                return console.log(data.errors[0]);
            }
            setPageCount(Math.round(data.TotalCount / 50));
            setTotalUsers(data.display.TotalCount);
            setSupportList(data.display.Support || []);
        } catch (error) {
            console.log('error', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getData();
    }, []);

    const handlePageChange = (event, value) => {
        setPage(value);
        pageChanged(value);
    };

    const resetFilters = (event) => {
        setAdvancedSearchOn(false);
        handlePageChange(event, 1);
    };

    const pageChanged = (value) => {
        const payload = searchInput ? { GenericFilter: searchInput } : filters ? getAppliedFiltersPost(filters) : null;
        getData(value, payload);
    };

    const handleOpenFilter = () => {
        setOpenFilter(true);
    };

    const handleCloseFilter = () => {
        setOpenFilter(false);
    };

    const advanceSearchHandler = () => {
        setAdvancedSearchOn(!advancedSearchOn);
    };

    return (
        <DrcContainer heading="Support">
            {isDesktop ? (
                <>
                    <Box className={styles.supportContainer}>
                        <TranslatedDrcButton
                            customBtnStyles={{ width: '100%', maxWidth: 'max-content', padding: '1rem' }}
                            nameSpacing={NAMESPACE.COMMON}
                            disableRipple
                            color="inherit"
                            endIcon={<Iconify icon={advancedSearchOn ? 'mdi:toggle-switch' : 'mdi:toggle-switch-off'} />}
                            onClick={advanceSearchHandler}
                        >
                            {'Advanced Search'}
                        </TranslatedDrcButton>
                        {!advancedSearchOn && (
                            <div
                                className={
                                    ![PERSONAID.GROWERUSER, PERSONAID.GROWERADMIN, PERSONAID.QUALITYVIEWER].includes(userPersona) && styles.searchBox
                                }
                            >
                                <GenericSearchBox
                                    style={{ width: '35vw' }}
                                    value={searchInput}
                                    setValue={setSearchInput}
                                    onEnterPress={() => getData(1, { GenericFilter: searchInput })}
                                />
                            </div>
                        )}

                        <PersonaBaseProtect personasAllowed={[Persona.GROWER_USER, Persona.GROWER_ADMIN, Persona.QUALITY_VIEWER,Persona.RANCH_ADMIN]} featureName={FEATURES.RAISE_AN_ISSUE} access='Write'>
                            <TranslatedDrcButton
                                nameSpacing={NAMESPACE.EVENTS}
                                customBtnStyles={{ width: '100%', maxWidth: 'max-content' }}
                                variant="contained"
                                endIcon={<Iconify icon="material-symbols:mail-outline-sharp" />}
                                onClick={() => {
                                    navigate('query');
                                }}
                            >
                                {'Raise an Issue'}
                            </TranslatedDrcButton>
                        </PersonaBaseProtect>
                    </Box>
                    {advancedSearchOn && (
                        <Grid container gap={2} sx={{ m: 2 }}>
                            <Filter
                                showFilter={advancedSearchOn}
                                applyFilter={() => {
                                    getData(1, getAppliedFiltersPost(filters));
                                    setAdvancedSearchOn(false);
                                }}
                                resetFilter={resetFilters}
                                filterNames={['IncidentNumber', 'Subject', 'Priority', 'Status']}
                            />
                        </Grid>
                    )}
                </>
            ) : (
                <>
                    <Box className={styles.supportContainer}>
                        <GenericFilterSideBar
                            openFilter={openFilter}
                            filterNames={['IncidentNumber', 'Subject', 'Priority', 'Status']}
                            onOpenFilter={handleOpenFilter}
                            onCloseFilter={handleCloseFilter}
                            getData={getData}
                            filters={filters}
                            resetFilters={resetFilters}
                        />
                        <PersonaBaseProtect personasAllowed={[Persona.GROWER_USER, Persona.GROWER_ADMIN,Persona.RANCH_ADMIN]} featureName={FEATURES.RAISE_AN_ISSUE} access='Write'>
                            <Button
                                variant="contained"
                                endIcon={<Iconify icon="material-symbols:mail-outline-sharp" />}
                                onClick={() => {
                                    navigate('query');
                                }}
                            >
                                <TypographyTranslated nameSpacing={NAMESPACE.EVENTS}>{'Raise an Issue'}</TypographyTranslated>
                            </Button>
                        </PersonaBaseProtect>
                    </Box>

                    <GenericSearchBox
                        style={{ mb: 2 }}
                        value={searchInput}
                        setValue={setSearchInput}
                        onEnterPress={() => getData(1, { GenericFilter: searchInput })}
                    />
                </>
            )}
            <SupportGridContainer
                handlePageChange={handlePageChange}
                page={page}
                totalUsers={totalUsers}
                executionTime={executionTime}
                loading={loading}
                supportList={supportList}
                pageChanged={pageChanged}
                pageCount={pageCount}
            />
        </DrcContainer>
    );
}

export default withOktaAuth(Support);
