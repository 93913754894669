import { IDistrict, IDistrictBerries } from 'components/appinitializer/IDistricts';
import { IUserDetails } from 'components/appinitializer/IUserDetails';
import { Persona } from 'components/shared/protected/PersonaBaseProtect';
import ApplicationContextV2 from 'context/ApplicationContextV2';
import { useContext, useEffect, useState } from 'react';
import { InitializeData } from 'services/InitializeData';
import APIEndPoints from 'services/api';
import { FeatureType } from 'types/application';
import useFormatMasterDataResponse from './useFormatMasterDataResponse';
import useGetApiTokens from './useGetApiTokens';
import useLang, { Lang } from './useLang';
import useFormatDataStructure from './v2/utils/useFormatDataStructure';

/**
 * This fetches all the masterdata calls
 * @param oktaAuth Okta auth object
 * @id has to be the identifier for the folder
 */
const useGetLoadMasterData = (oktaAuth: any) => {
    const [message, setMessage] = useState<string>(null);
    const [successCount, setSuccessCount] = useState<number>(0);
    const [errorCount, setErrorCount] = useState<number>(0);
    const [totalCount, setTotalCount] = useState<number>(0);
    const { getTokenAndId } = useGetApiTokens();
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [executionTime, setExecutionTime] = useState(0);
    const [userStatus, setUserStatus] = useState<boolean>(false);
    const [userFeatures, setUserFeatures] = useState<FeatureType[]>([]);
    const [basicInformation, setBasicInformation] = useState<any>(null);
    const [userProfile, setUserProfile] = useState<IUserDetails>(null);
    const [userLang, setUserLang] = useState<string>(null);
    const [activeLanguage, setActiveLanguage] = useState<string>(null);
    const [districtOptions, setDistrictOptions] = useState<any[]>([]);
    const [districtBerries, setDistrictBerries] = useState([] as Array<IDistrictBerries>);
    const [reportsList, setReportsList] = useState<any>([]);
    const [isMasterDataInitialized, setMasterDataInitialized] = useState<boolean>(false);
    const { getCompanyData, getRanchesData, getBerriesData } = useFormatMasterDataResponse();
    const [companies, setCompanies] = useState<any>([]);
    const [ranches, setRanches] = useState<any>([]);
    const [usersLanguage, setUsersLanguage] = useState<Lang>();
    const { getCurrentLanguageSelected, changeLanguage } = useLang();

    const { getDistricts, getCompanies } = useFormatDataStructure();
    const { setDistrictEntities, setCompanyEntities, setCountries } = useContext(ApplicationContextV2);

    const {
        SUPER_ADMIN,
        REGIONAL_ADMIN,
        HARVEST_PLANNER,
        GROWER_ADMIN,
        RANCH_PLANNER,
        PALLET_BUILDER,
        PTI_APP,
        GROWER_USER,
        PORTAL_USER,
        PTI_USER,
        REPORT_BUILDER,
        QUALITY_VIEWER,
        RANCH_ADMIN
    } = Persona;

    const getMasterDataCalls = (userOktaId: string, loggedInUser: string, persona: number) => {
        return [
            {
                name: 'features',
                url: APIEndPoints.GET_USER_FEATURES(userOktaId),
                method: 'GET',
                options: {},
                returnFunction: (data) => {
                    setUserFeatures(data?.display ?? []);
                }
            },
            {
                name: 'status',
                url: APIEndPoints.GET_USER_STATUS(userOktaId),
                method: 'GET',
                options: {},
                returnFunction: (data) => {
                    setUserStatus(data.display.User.IsActive || false);
                }
            },
            {
                name: 'BasicInformation',
                url: APIEndPoints.GET_BASIC_INFORMATION(0),
                method: 'GET',
                options: {},
                returnFunction: (data) => {
                    setBasicInformation(data?.display);
                    setCountries(data?.display?.Countries);
                }
            },
            {
                name: 'UserProfileDetails',
                url: APIEndPoints.GET_PERSONA_DETAILS(),
                method: 'POST',
                options: {},
                data: { UserOktaId: userOktaId },
                returnFunction: (res) => {
                    const profile = res.display?.resultSet1.length > 0 && res.display?.resultSet1[0];
                    setUserProfile({
                        profile: {
                            firstName: profile.FirstName,
                            lastName: profile.LastName,
                            displayName: profile.FirstName + ' ' + profile.LastName,
                            mobilePhone: profile.Phone,
                            email: profile.Email,
                            gpaLang: profile.Lang
                        },
                        id: profile.AuthId
                    });
                    setUserLang(profile?.Lang || 'en');
                    setUsersLanguage(profile.Lang);
                    sessionStorage.setItem('GEP_UserLang', profile?.Lang || 'en');
                    setActiveLanguage(profile?.Lang || 'en');
                }
            },
            {
                allowedPersonas: [SUPER_ADMIN, REGIONAL_ADMIN, HARVEST_PLANNER, PORTAL_USER],
                name: 'District',
                url:
                    persona === SUPER_ADMIN || persona === PORTAL_USER
                        ? APIEndPoints.GET_SUPERADMIN_DISTRICTS()
                        : APIEndPoints.GET_DISTRICTS(persona, userOktaId),
                method: 'GET',
                options: {},
                returnFunction: (data) => {
                    let options: Array<IDistrict> = [];
                    options = (data?.display || []).map((item) => {
                        return {
                            DistrictName: item.GrowingDistrictName || item.DistrictName,
                            DistrictCode: item.GrowingDistrictCode || item.DistrictCode,
                            DistrictId: item.GrowingDistrictId || item.DistrictId,
                            label: item.GrowingDistrictName
                                ? item.GrowingDistrictName + ' - ' + item.GrowingDistrictCode
                                : item.DistrictName + ' - ' + item.DistrictCode,
                            value: item.GrowingDistrictCode || item.DistrictCode,
                            ...item
                        };
                    });

                    setDistrictOptions(options);
                }
            },
            {
                allowedPersonas: [SUPER_ADMIN, PTI_USER],
                name: 'District',
                url: APIEndPoints.GET_DISTRICTS(persona, userOktaId),
                method: 'GET',
                options: {},
                returnFunction: (data) => {
                    setDistrictBerries(getBerriesData(data));
                }
            },
            {
                allowedPersonas: [SUPER_ADMIN, REGIONAL_ADMIN, GROWER_ADMIN, PALLET_BUILDER, PORTAL_USER, REPORT_BUILDER, RANCH_ADMIN],
                name: 'Companies',
                url: APIEndPoints.GET_COMPANIES_FOR_PERSONA(persona, userOktaId),
                method: 'GET',
                options: {},
                returnFunction: (data) => {
                    setCompanies(getCompanyData(persona, data));
                }
            },
            {
                allowedPersonas: [
                    SUPER_ADMIN,
                    REGIONAL_ADMIN,
                    GROWER_ADMIN,
                    RANCH_PLANNER,
                    PTI_USER,
                    PTI_APP,
                    GROWER_USER,
                    PORTAL_USER,
                    QUALITY_VIEWER,
                    REPORT_BUILDER,
                    RANCH_ADMIN
                ],
                name: 'Ranches',
                url:
                    persona === SUPER_ADMIN || persona === PORTAL_USER
                        ? APIEndPoints.GET_SUPERADMIN_RANCHES()
                        : APIEndPoints.GET_RANCHES_FOR_PERSONA(persona, userOktaId),
                method: 'GET',
                options: {},
                returnFunction: (data) => {
                    const { ranchOptions, districts } = getRanchesData(persona, data);
                    setRanches(ranchOptions);
                    if (districts) {
                        setDistrictOptions(districts);
                    }
                }
            }
        ];
    };

    useEffect(() => {
        // to set the preferred language of the user
        if (getCurrentLanguageSelected() !== usersLanguage) {
            changeLanguage(usersLanguage);
        }
    }, [changeLanguage, getCurrentLanguageSelected, usersLanguage]);

    useEffect(() => {
        if (window.config.uiConfig.v2UserManagement === 'true') {
            if (companies.length > 0 && ranches.length > 0) {
                setDistrictEntities(companies[0].Companies ? getDistricts(companies) : getDistricts(districtOptions, companies));
                setCompanyEntities(ranches[0].Ranches ? getCompanies(ranches) : getCompanies(companies, ranches));
            }
        }
    }, [districtOptions, companies, ranches]);

    const loadMasterData = async (persona: number) => {
        const { userEmail, userOktaId } = await getTokenAndId(oktaAuth);
        let masterDataCalls = getMasterDataCalls(userOktaId, userEmail, persona);
        masterDataCalls = masterDataCalls.filter(
            (item) => !item.allowedPersonas || (item.allowedPersonas && item.allowedPersonas.indexOf(persona) > -1)
        );
        await InitializeData.LoadApis(oktaAuth, masterDataCalls, statusUpdate, (message) => {
            setMasterDataInitialized(true);
        });
    };

    const statusUpdate = (message: string, successCount: number, errorCount: number, totalCount: number) => {
        setMessage(message);
        setSuccessCount(successCount);
        setErrorCount(errorCount);
        setTotalCount(totalCount);
    };

    return {
        activeLanguage,
        userLang,
        companies,
        reportsList,
        ranches,
        districtOptions,
        districtBerries,
        isMasterDataInitialized,
        error,
        executionTime,
        loading,
        loadMasterData,
        userStatus,
        userFeatures,
        basicInformation,
        userProfile,
        message,
        successCount,
        errorCount,
        totalCount
    };
};
export default useGetLoadMasterData;
