import { IBerryType, IDistrictBerries } from 'components/appinitializer/IDistricts';
import { Persona } from 'components/shared/protected/PersonaBaseProtect';
import { COMMODITY_LABEL, commodityObj } from 'constants/CommodityEnum';

const useFormatMasterDataResponse = () => {
    const getCompanyData = (persona: number, response: any) => {
        const result = persona === Persona.SUPER_ADMIN || persona === Persona.PORTAL_USER ? response?.display.Data : response?.display;
        let options = [];
        if (persona === Persona.SUPER_ADMIN || persona === Persona.PORTAL_USER) {
            options = (result || []).map((item) => {
                return {
                    ...item,
                    label: item.CompanyCode + ' - ' + item.CompanyName,
                    value: item.CompanyCode,
                    CompanyName: item.CompanyName,
                    CompanyId: item.CompanyId,
                    CompanyNbr: item.CompanyCode,
                    Name: item.CompanyName,
                    Code: item.CompanyCode,
                    Id: item.CompanyId
                };
            });
        } else {
            options = (result || []).map((item) => {
                return {
                    ...item,
                    label: item.DistrictCode + ' - ' + item.DistrictName,
                    value: item.DistrictCode,
                    DistrictName: item.DistrictName,
                    DistrictCode: item.DistrictCode,
                    DistrictId: item.DistrictId
                };
            });
        }

        return options;
    };

    const getRanchesData = (persona: number, response: any) => {
        const result =
            persona === Persona.SUPER_ADMIN || persona === Persona.PORTAL_USER
                ? response?.display.Data
                : persona === Persona.GROWER_USER
                ? response.display.GrowerUserAssignment
                : persona === Persona.QUALITY_VIEWER
                ? response.display.QualityViewerAssignment
                : response?.display;
        let ranchOptions = [];
        let districts;
        if (persona === Persona.SUPER_ADMIN || persona === Persona.PORTAL_USER) {
            ranchOptions = (result || []).map((item) => {
                return {
                    ...item,
                    label: item.RanchNbr + ' - ' + item.Name,
                    value: item.RanchNbr,
                    Name: item.Name,
                    Id: item.RanchId,
                    Code: item.RanchNbr
                };
            });
        } else {
            ranchOptions = (result || []).map((item) => {
                return {
                    ...item,
                    Id: item.CompanyId,
                    label: `${item.CompanyName ?? item.GrowerName} - ${item?.CompanyNumber ?? item.GrowerNbr}`,
                    CompanyName: item.CompanyName ?? item.GrowerName,
                    CompanyNbr: item.CompanyNumber ?? item.GrowerNbr,
                    CompanyId: item.CompanyId ?? item.GrowerId,
                    value: item.CompanyNumber ?? item.GrowerNbr
                };
            });
        }
        if (persona === Persona.GROWER_USER || persona === Persona.QUALITY_VIEWER) {
            const arr = result.map((item) => ({
                label: item.DistrictCode + ' - ' + item.DistrictName,
                value: item.DistrictCode,
                DistrictName: item.DistrictName,
                DistrictCode: item.DistrictCode,
                DistrictId: item.DistrictId
            }));
            districts = arr.filter((value, index, self) => index === self.findIndex((t) => t.DistrictId === value.DistrictId));
        }
        return { ranchOptions, districts };
    };

    const getBerriesData = (data): Array<IDistrictBerries> => {
        return (data?.display || []).map((item) => {
            return {
                DistrictName: item.DistrictName,
                DistrictCode: item.DistrictCode,
                DistrictId: item.DistrictId,
                label: item.DistrictName + ' - ' + item.DistrictCode,
                value: item.GrowingDistrictCode || item.DistrictCode,
                BerryTypes: item.BerriesAssigned.map((berry: IBerryType) => ({
                    label: berry.Name,
                    value: commodityObj[berry.Name as COMMODITY_LABEL].code,
                    ...berry
                }))
            };
        });
    };

    return { getCompanyData, getRanchesData, getBerriesData };
};

export default useFormatMasterDataResponse;
