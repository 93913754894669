import { FEATURES } from 'components/appinitializer/v2/IPersonaFeatures';
import DrcPageNotFound from 'components/shared/drc/DrcPageNotFound';
import PersonaBaseProtect, { Persona } from 'components/shared/protected/PersonaBaseProtect';
import ProtectedRoute from 'components/shared/protected/ProtectedRoute';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { routes, routesName } from 'routes/routes';
import QueryForm from './QueryForm';
import ResponseForm from './ResponseForm';
import Support from './Support';
import ViewResponse from './ViewResponse';

const SupportRoutes = () => {
    return (
        <Routes>
            <Route
                path=""
                element={
                    <PersonaBaseProtect
                        personasAllowed={[
                            Persona.SUPER_ADMIN,
                            Persona.REGIONAL_ADMIN,
                            Persona.GROWER_USER,
                            Persona.GROWER_ADMIN,
                            Persona.QUALITY_VIEWER,
                            Persona.HARVEST_PLANNER,
                            Persona.QA_ADMIN,
                            Persona.RANCH_PLANNER,
                            Persona.PALLET_BUILDER,
                            Persona.RANCH_ADMIN
                        ]}
                        featureName={FEATURES.SUPPORT}
                        access="Read"
                        forbiddenComponent={<DrcPageNotFound />}
                    >
                        <Support />
                    </PersonaBaseProtect>
                }
            />
            <Route
                path={routes[routesName.QUERY].path}
                element={
                    <ProtectedRoute>
                        <PersonaBaseProtect
                            personasAllowed={[Persona.GROWER_USER, Persona.GROWER_ADMIN, Persona.QUALITY_VIEWER, Persona.RANCH_ADMIN]}
                            featureName={FEATURES.SUPPORT}
                            access="Read"
                        >
                            <QueryForm />
                        </PersonaBaseProtect>
                    </ProtectedRoute>
                }
            />
            <Route
                path={routes[routesName.SUPPORT_RESOLUTION].path}
                element={
                    <ProtectedRoute>
                        <PersonaBaseProtect
                            personasAllowed={[Persona.SUPER_ADMIN, Persona.REGIONAL_ADMIN]}
                            featureName={FEATURES.SUPPORT}
                            access="Read"
                        >
                            <ResponseForm />
                        </PersonaBaseProtect>
                    </ProtectedRoute>
                }
            />
            <Route
                path={routes[routesName.VIEW_RESOLUTION].path}
                element={
                    <ProtectedRoute>
                        <PersonaBaseProtect
                            personasAllowed={[Persona.GROWER_USER, Persona.GROWER_ADMIN, Persona.QUALITY_VIEWER, Persona.RANCH_ADMIN]}
                            featureName={FEATURES.SUPPORT}
                            access="Read"
                        >
                            <ViewResponse />
                        </PersonaBaseProtect>
                    </ProtectedRoute>
                }
            />
        </Routes>
    );
};

export default SupportRoutes;
