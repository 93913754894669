import {
    AvatarSize,
    ButtonVariant,
    DriscollsAvatar,
    DriscollsMenu,
    DriscollsMenuDivider,
    DriscollsMenuItem,
    DriscollsSubMenu,
    DriscollsTypography,
    DriscollsTypographyEnum,
    DriscollsTypographyStyles,
    DriscollsTypographyTranslated,
    DriscollsVContainer
} from '@driscollsinc/style-guide-web2.0';
import { useOktaAuth } from '@okta/okta-react';
import { IProfile } from 'components/appinitializer/IUserDetails';
import DuAuthenticationUtilities from 'components/shared/Utilities/DuAuthenticationUtilities';
import { ROUTES } from 'constants/routes';
import ApplicationContext from 'context/ApplicationContext';
import { isPersonaAllowedInTheApp } from 'data/config/datasetup';
import useLang from 'hooks/useLang';
import { NAMESPACE } from 'i18n';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { routes, routesName } from 'routes/routes';
import styles from './AccountMenu.module.scss';

const AccountMenu = () => {
    const navigate = useNavigate();
    const { authState } = useOktaAuth();
    const {
        userPersona,
        setUserPersona,
        userPersonas,
        userProfile = { profile: {} as IProfile },
        setMasterDataInitialized
    } = useContext(ApplicationContext);
    const [account, setAccount] = useState({ displayName: '', email: '' });
    const { getTextTranslated } = useLang();

    useEffect(() => {
        const idp = DuAuthenticationUtilities.getAuthProvider();
        if (idp === 'okta') {
            if (authState?.isAuthenticated) {
                const userEmail = authState?.idToken?.claims?.email?.toUpperCase();
                const auth = {
                    displayName: userProfile !== null ? userProfile.profile.displayName : '',
                    email: userEmail.toLowerCase()
                };
                setAccount(auth);
            }
        } else {
            const cognitoUser = JSON.parse(localStorage.getItem('cognitouser'));
            const auth = {
                displayName: userProfile !== null ? userProfile.profile.firstName : '',
                email: cognitoUser?.attributes?.phone_number
            };
            setAccount(auth);
        }
    }, [authState, userProfile]);

    const handleMakeDefaultPersona = async (persona: number) => {
        window.history.replaceState(null, null, `${ROUTES.ROOT}${ROUTES.DASHBOARD}`);
        localStorage.setItem('defaultPersona', String(persona));
        setUserPersona(persona);
        setMasterDataInitialized(false);
        navigate(0);
    };

    const logout = () => {
        navigate(routes[routesName.LOGOUT].getRoute());
    };

    return (
        <DriscollsMenu
            buttonVariant={ButtonVariant.TEXT}
            labelIcon={<DriscollsAvatar className={styles.avatar} text={account.displayName} size={AvatarSize.SMALL} displayOneCharacter={true} />}
        >
            <DriscollsMenuItem className={styles.name}>
                <DriscollsVContainer noPadding>
                    <DriscollsTypography typographyStyle={DriscollsTypographyStyles.SUBTITLE5} type={DriscollsTypographyEnum.H6}>
                        {account.displayName}
                    </DriscollsTypography>
                    <DriscollsTypography typographyStyle={DriscollsTypographyStyles.SUBTITLE5} type={DriscollsTypographyEnum.P}>
                        {account.email}
                    </DriscollsTypography>
                </DriscollsVContainer>
            </DriscollsMenuItem>
            <DriscollsMenuDivider />
            <DriscollsMenuItem value="Profile" onClick={() => navigate(routes[routesName.PROFILE].getRoute())}>
                <DriscollsTypographyTranslated
                    typographyStyle={DriscollsTypographyStyles.SUBTITLE5}
                    type={DriscollsTypographyEnum.P}
                    nameSpacing={NAMESPACE.COMMON}
                >
                    {'Profile'}
                </DriscollsTypographyTranslated>
            </DriscollsMenuItem>

            <DriscollsSubMenu label={getTextTranslated('Accounts', NAMESPACE.COMMON)}>
                {(userPersonas || []).map(
                    (option) =>
                        isPersonaAllowedInTheApp(option.PersonaId) && (
                            <DriscollsMenuItem
                                type="checkbox"
                                checked={userPersona === option.PersonaId}
                                key={option.PersonaId}
                                onClick={() => {
                                    handleMakeDefaultPersona(option.PersonaId);
                                }}
                            >
                                <DriscollsTypographyTranslated
                                    typographyStyle={DriscollsTypographyStyles.SUBTITLE5}
                                    type={DriscollsTypographyEnum.P}
                                    nameSpacing={NAMESPACE.COMMON}
                                >
                                    {option.PersonaName}
                                </DriscollsTypographyTranslated>
                            </DriscollsMenuItem>
                        )
                )}
            </DriscollsSubMenu>
            <DriscollsMenuDivider />
            <DriscollsMenuItem value="Logout" onClick={logout}>
                <DriscollsTypographyTranslated
                    typographyStyle={DriscollsTypographyStyles.SUBTITLE5}
                    type={DriscollsTypographyEnum.P}
                    nameSpacing={NAMESPACE.COMMON}
                >
                    {'Logout'}
                </DriscollsTypographyTranslated>
            </DriscollsMenuItem>
        </DriscollsMenu>
    );
};

export default AccountMenu;
