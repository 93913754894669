import { FEATURES } from 'components/appinitializer/v2/IPersonaFeatures';
import PersonaBaseProtect, { Persona } from 'components/shared/protected/PersonaBaseProtect';
import ProtectedRoute from 'components/shared/protected/ProtectedRoute';
import { ROUTES } from 'constants/routes';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import getRoutePath from 'routes/routesUtils';
import CreateEvent from './CreateEvent';
import EditEvent from './EditEvent';
import ViewAllEvents from './ViewAllEvents';
import ViewEventDetails from './ViewEventDetails';

const EventsRoutes = () => {
    return (
        <Routes>
            <Route
                path={getRoutePath(ROUTES.EVENT_DETAILS)}
                element={
                    <ProtectedRoute>
                        <ViewEventDetails />
                    </ProtectedRoute>
                }
            />

            <Route
                path={ROUTES.EVENTSCREATE}
                element={
                    <ProtectedRoute>
                        <PersonaBaseProtect
                            personasAllowed={[
                                Persona.SUPER_ADMIN,
                                Persona.REGIONAL_ADMIN,
                                Persona.GROWER_ADMIN,
                                Persona.QA_ADMIN,
                                Persona.RANCH_ADMIN
                            ]}
                            featureName={FEATURES.DASHBOARD_EVENTS}
                            access="Write"
                        >
                            <CreateEvent />
                        </PersonaBaseProtect>
                    </ProtectedRoute>
                }
            />
            <Route
                path={getRoutePath(ROUTES.EVENT_EDIT)}
                element={
                    <ProtectedRoute>
                        <PersonaBaseProtect
                            personasAllowed={[
                                Persona.SUPER_ADMIN,
                                Persona.REGIONAL_ADMIN,
                                Persona.GROWER_ADMIN,
                                Persona.QA_ADMIN,
                                Persona.RANCH_ADMIN
                            ]}
                            featureName={FEATURES.DASHBOARD_EVENTS}
                            access="Write"
                        >
                            <EditEvent />
                        </PersonaBaseProtect>
                    </ProtectedRoute>
                }
            />
            <Route
                path={''}
                element={
                    <ProtectedRoute>
                        <ViewAllEvents />
                    </ProtectedRoute>
                }
            />
        </Routes>
    );
};

export default EventsRoutes;
