import { FEATURES } from 'components/appinitializer/v2/IPersonaFeatures';
import DrcPageNotFound from 'components/shared/drc/DrcPageNotFound';
import PersonaBaseProtect, { Persona } from 'components/shared/protected/PersonaBaseProtect';
import { ROUTES } from 'constants/routes';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import AuthoringReport from './AuthoringReport';
import CanadaManualTaxReport from './CanadaManualTaxReport';
import DailyWeeklyReport from './DailyWeeklyReport';
import DownloadQAReport from './DownloadQAReport';
import FreshFruitReceivingReport from './FreshFruitReceivingReport';
import GrowerSettlementReport from './GrowerSettlementReport';
import Reports from './Reports';
import SupplementaryReports from './SupplementaryReports';
import UploadReport from './UploadReport';
import ViewReport from './ViewReport';

const ReportsRoutes = () => {
    return (
        <Routes>
            <Route
                path=""
                element={
                    <PersonaBaseProtect
                        forbiddenComponent={<DrcPageNotFound />}
                        personasAllowed={[
                            Persona.SUPER_ADMIN,
                            Persona.REGIONAL_ADMIN,
                            Persona.GROWER_ADMIN,
                            Persona.GROWER_USER,
                            Persona.QUALITY_VIEWER,
                            Persona.PORTAL_USER,
                            Persona.REPORT_BUILDER,
                            Persona.RANCH_ADMIN
                        ]}
                        featureName={FEATURES.REPORTS}
                        access="Read"
                    >
                        <Reports />
                    </PersonaBaseProtect>
                }
            />
            <Route
                path={ROUTES.VIEW_REPORT}
                element={
                    <PersonaBaseProtect
                        forbiddenComponent={<DrcPageNotFound />}
                        personasAllowed={[
                            Persona.SUPER_ADMIN,
                            Persona.REGIONAL_ADMIN,
                            Persona.GROWER_ADMIN,
                            Persona.GROWER_USER,
                            Persona.QUALITY_VIEWER,
                            Persona.PORTAL_USER,
                            Persona.REPORT_BUILDER,
                            Persona.RANCH_ADMIN
                        ]}
                        featureName={FEATURES.REPORTS}
                        access="Read"
                    >
                        <ViewReport />
                    </PersonaBaseProtect>
                }
            />
            <Route
                path={ROUTES.AUTHORING_REPORT}
                element={
                    <PersonaBaseProtect
                        personasAllowed={[Persona.SUPER_ADMIN, Persona.REPORT_BUILDER]}
                        featureName={FEATURES.BUILD_REPORT}
                        access="Read"
                    >
                        <AuthoringReport />
                    </PersonaBaseProtect>
                }
            />
            <Route
                path={ROUTES.VIEW_WEEKLY_REPORT}
                element={
                    <PersonaBaseProtect
                        personasAllowed={[
                            Persona.GROWER_ADMIN,
                            Persona.RANCH_PLANNER,
                            Persona.SUPER_ADMIN,
                            Persona.REGIONAL_ADMIN,
                            Persona.GROWER_USER,
                            Persona.PORTAL_USER,
                            Persona.QUALITY_VIEWER,
                            Persona.RANCH_ADMIN
                        ]}
                        featureName={FEATURES.REPORTS}
                        access="Read"
                        forbiddenComponent={<DrcPageNotFound />}
                    >
                        <DailyWeeklyReport />
                    </PersonaBaseProtect>
                }
            />
            <Route
                path={ROUTES.VIEW_GROWER_SETTLEMENT_REPORT}
                element={
                    <PersonaBaseProtect
                        personasAllowed={[
                            Persona.GROWER_ADMIN,
                            Persona.RANCH_PLANNER,
                            Persona.SUPER_ADMIN,
                            Persona.REGIONAL_ADMIN,
                            Persona.GROWER_USER,
                            Persona.PORTAL_USER,
                            Persona.QUALITY_VIEWER,
                            Persona.RANCH_ADMIN
                        ]}
                        featureName={FEATURES.REPORTS}
                        access="Read"
                        forbiddenComponent={<DrcPageNotFound />}
                    >
                        <GrowerSettlementReport />
                    </PersonaBaseProtect>
                }
            />
            <Route
                path={ROUTES.VIEW_SUPPLEMENTAL_STATEMENT_REPORT}
                element={
                    <PersonaBaseProtect
                        personasAllowed={[
                            Persona.GROWER_ADMIN,
                            Persona.RANCH_PLANNER,
                            Persona.SUPER_ADMIN,
                            Persona.REGIONAL_ADMIN,
                            Persona.GROWER_USER,
                            Persona.PORTAL_USER,
                            Persona.QUALITY_VIEWER,
                            Persona.RANCH_ADMIN
                        ]}
                        featureName={FEATURES.REPORTS}
                        access="Read"
                        forbiddenComponent={<DrcPageNotFound />}
                    >
                        <SupplementaryReports />
                    </PersonaBaseProtect>
                }
            />
            <Route
                path={ROUTES.VIEW_CANADA_MANUAL_TAX_REPORT}
                element={
                    <PersonaBaseProtect
                        personasAllowed={[
                            Persona.GROWER_ADMIN,
                            Persona.RANCH_PLANNER,
                            Persona.SUPER_ADMIN,
                            Persona.REGIONAL_ADMIN,
                            Persona.GROWER_USER,
                            Persona.PORTAL_USER,
                            Persona.QUALITY_VIEWER,
                            Persona.RANCH_ADMIN
                        ]}
                        featureName={FEATURES.REPORTS}
                        access="Read"
                        forbiddenComponent={<DrcPageNotFound />}
                    >
                        <CanadaManualTaxReport />
                    </PersonaBaseProtect>
                }
            />
            <Route
                path={ROUTES.UPLOAD_REPORT}
                element={
                    <PersonaBaseProtect
                        personasAllowed={[Persona.PORTAL_USER]}
                        featureName={FEATURES.REPORTS}
                        access="Read"
                        forbiddenComponent={<DrcPageNotFound />}
                    >
                        <UploadReport />
                    </PersonaBaseProtect>
                }
            />
            <Route
                path={ROUTES.VIEW_FRESH_FRUIT_RECEIVING_REPORT}
                element={
                    <PersonaBaseProtect
                        personasAllowed={[
                            Persona.GROWER_ADMIN,
                            Persona.SUPER_ADMIN,
                            Persona.REGIONAL_ADMIN,
                            Persona.GROWER_USER,
                            Persona.RANCH_ADMIN
                        ]}
                        featureName={FEATURES.REPORTS}
                        access="Read"
                        forbiddenComponent={<DrcPageNotFound />}
                    >
                        <FreshFruitReceivingReport />
                    </PersonaBaseProtect>
                }
            />
            {/* This Route is needed for One Driscoll's Mobile App! */}
            <Route path={ROUTES.DOWNLOAD_REPORT_FILE} element={<DownloadQAReport />} />
        </Routes>
    );
};

export default ReportsRoutes;
