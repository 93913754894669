import PropTypes from 'prop-types';
import React, { Fragment, useContext } from 'react';
// @mui
import { AppBar, Box, IconButton, Stack, Toolbar } from '@mui/material';
import { styled } from '@mui/material/styles';
// utils
import { bgBlur } from '../../../utils/cssStyles';
// components
import sideLogo from '../../../Images/Logo_Berries.png';
import logo from '../../../Images/Logo_Small_Transparent.png';
import Iconify from '../../../components/shared/iconify';
//
import { TypographyTranslated } from 'components/shared/Typography/TypographyTranslated';
import ApplicationContext from 'context/ApplicationContext';
import useLang from 'hooks/useLang';

import { NAMESPACE } from 'i18n';
import { Link } from 'react-router-dom';
import { logEvent } from '../../../analytics/AnalyticsHandler';
import DrcEnvironmentMarker from '../../../components/shared/drc/DrcEnvironmentMarker';
import AccountMenu from '../../../components/v2/popovers/AccountMenu';
import { EventConstants } from '../../../constants/EventConstants';
import useResponsive from '../../../hooks/useResponsive';
import LanguagePopover from './LanguagePopover';
import PersonaFeaturePopover from '../../../components/v2/popovers/PersonaFeaturePopover';
// ----------------------------------------------------------------------

const HEADER_MOBILE = 64;

const HEADER_DESKTOP = 92;

const StyledRoot = styled(AppBar)(({ theme }) => ({
    ...bgBlur({ color: theme.palette.background.default }),
    boxShadow: 'none',
    [theme.breakpoints.up('lg')]: {
        width: '100%'
    }
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
    minHeight: HEADER_MOBILE,
    backgroundColor: '#F6F5FA',
    [theme.breakpoints.up('lg')]: {
        minHeight: HEADER_DESKTOP,
        padding: theme.spacing(0, 5)
    }
}));

// ----------------------------------------------------------------------

Header.propTypes = {
    onOpenNav: PropTypes.func
};

export const AppTitle = ({ appTitle }) => {
    return (
        <TypographyTranslated
            nameSpacing={NAMESPACE.COMMON}
            sx={{
                textAlign: 'center',
                color: '#2b2a2a',
                fontFamily: 'Rubik',
                fontSize: '25px',
                fontWeight: '600',
                letterSpacing: '1.39px',
                textTransform: 'uppercase'
            }}
        >
            {appTitle}
        </TypographyTranslated>
    );
};

export default function Header({ onOpenNav }) {
    const { changeLanguage } = useLang();
    const { setUserPersona, landingPage } = useContext(ApplicationContext);
    const isDesktop = useResponsive('up', 'md');
    return (
        <StyledRoot>
            <StyledToolbar>
                <DrcEnvironmentMarker />
                <div style={{ position: 'absolute', left: 0, top: 0 }}>
                    <Link to={landingPage}>
                        <img style={{ height: '80px', margin: '-5px 0 0 -6px' }} src={sideLogo} alt="berries" />
                    </Link>
                </div>
                <IconButton
                    onClick={onOpenNav}
                    sx={{
                        mr: 1,
                        color: 'text.primary',
                        paddingLeft: '25px',
                        display: { md: 'none' }
                    }}
                >
                    <Iconify icon="eva:menu-2-fill" />
                </IconButton>
                {isDesktop && (
                    <Fragment>
                        <Link to={landingPage}>
                            <img style={{ height: '40px', marginLeft: '20px' }} src={logo} alt="drc logo" />
                        </Link>
                        <Box sx={{ flexGrow: 1 }} />
                        <AppTitle appTitle={'Grower Engagement Platform'} />
                    </Fragment>
                )}

                <Box sx={{ flexGrow: 1 }} />
                <Stack
                    direction="row"
                    alignItems="center"
                    spacing={{
                        xs: 0.5,
                        sm: 1
                    }}
                >
                    {window.config.uiConfig.v2EnablePersonaFeatureManagement === 'true' ? <PersonaFeaturePopover /> : null}
                    <LanguagePopover
                        handleLangChange={(code, _lang) => {
                            logEvent(EventConstants.CHANGE_LANGUAGE, code);
                            // i18n new version
                            changeLanguage(code);
                            sessionStorage.setItem('GEP_UserLang', code);
                        }}
                    />
                    <AccountMenu />
                </Stack>
            </StyledToolbar>
        </StyledRoot>
    );
}
