export const SET_MASTER_DATA_REDIRECT_URL = 'SET_MASTER_DATA_REDIRECT_URL';
export const setInitializeRedirectUrl = (redirectUrl) => ({
    type: SET_MASTER_DATA_REDIRECT_URL,
    payload: redirectUrl
});

export const SET_MASTER_DATA_INITIALIZED = 'SET_MASTER_DATA_INITIALIZED';
export const setMasterDataInitialized = (isInitialized) => ({
    type: SET_MASTER_DATA_INITIALIZED,
    payload: isInitialized
});
